export default {
  brown: "#816456",
  gray: "#666",
  bgLight: "#FEE580", // "rgba(255,255,255, 0.5)"
  productPrime: "#d40046", // "rgba(255,255,255, 0.5)"
  productBg1: "#e6e7e9", // "rgba(255,255,255, 0.5)"
  actionBanner: "#d4c1d4",
  green: "#70c13e",
  black: "#1111111",
  ligth_gray: "#999",
  white: "#FFFFFF",
  light_green: "#84d552",
  dark_gray: "#383838",
  gray_green: "#434a4c",
  graylight: "#ccc",
  red: "#c9302c",
  red_light: "#d9534f",
};

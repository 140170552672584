import React, { FunctionComponent, useEffect } from "react";

export const HeadBlocker: FunctionComponent = () => {
  useEffect(() => {
    const head = document.getElementsByTagName("head")[0] as any;
    const insertBefore = head.insertBefore;
    head.insertBefore = function (newElement: any, referenceElement: any) {
      if (
        newElement &&
        newElement.href &&
        newElement.href.indexOf("fonts.googleapis.com") > -1
      ) {
        return;
      }
      insertBefore.call(head, newElement, referenceElement);
    };
  }, []);

  return null;
};

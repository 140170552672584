import { css } from "styled-components";
import { BREAKPOINTS, BREAKPOINTS_DM } from "../../constants/mediaquerys";

export const PhotoGalleryStyleGlobal = css`
    [data-gallery] {
        .slick-arrow.slick-next {
            :before {
                content: "→";
                font-weight: 800;
                font-size: 32px;
                color: black;
            }
        }
        .slick-arrow.slick-prev {
            left: -35px;
            :before {
                content: "←";
                font-weight: 800;
                font-size: 32px;
                color: black;
            }
        }
        .slick-track,
        .slick-list {
            [data-image] {
                img {
                    height: min(calc(100vw - 55px), 600px);
                }
            }
            max-height: min(calc(100vw - 55px), 600px);
        }

        [data-thumnail-image] {
            img {
                height: 100px;
            }
        }

        &.gallerywithimagedescriptionundermainimages,
        &.gallerywiththumbnails {
            .slick-track,
            .slick-list {
                [data-image] {
                    img {
                        height: min(calc(100vw - 55px), 600px);
                    }
                }
                max-height: min(calc(100vw - 55px + 240px), 840px);
            }
        }

        [data-gallery-slider-tracker] {
            max-height: 100px;
            .slick-track,
            .slick-list {
                max-height: 100px;
            }
        }

        max-width: calc(100vw - 50px);
        @media (${`max-width: ${BREAKPOINTS.GOLD / 16}em`}) {
            max-width: none;
        }
    }

    *:not(.pg_riverimage) {
        & > [data-gallery] {
            @media (${`max-width: ${BREAKPOINTS.PLATINUM / 16}em`}) {
                padding: 30px !important;
            }
        }
    }
`;

import { reset } from "src/common/constants/reset";

import { createGlobalStyle, css } from "styled-components";
import t27VariablesBaseStyle from "./baseStyle";

export const DarkTheme = css`
    --back-1: #505050;
    --back-2: #404040;
    --back-3: #605950;
    --form-text: #ffffff;
    --front-1: #000000;
    --front-2: #ffffff;
    --front-3: #797979;
    --special-logobg: #ffffff;
    --special-logocolor: #000000;
`;

export const LightTheme = css`
    --back-1: #ffffff;
    --back-2: #f9f9f9;
    --back-3: #eeeeee;
    --form-text: #797979;
    --front-1: #000000;
    --front-2: #505050;
    --front-3: #797979;
    --special-logobg: #ffffff;
    --special-logocolor: #000000;
`;

export const CSSWithOpacityHoverAnimation = css`
    opacity: 1;
    transition: opacity 0.3s ease-in-out;

    &:hover,
    &:focus {
        opacity: 0.8;
    }
`;

const base = css`
    ${t27VariablesBaseStyle}

    *,
  *:before,
  *:after {
        box-sizing: border-box;
    }

    body {
        background-color: var(--back-1);
        overflow-x: hidden;
        height: 100%;
    }
    html {
        min-height: 100%;
    }

    div.cpg-item-img.no-img {
        display: none;
    }

    @media (max-width: 768px) {
        .ccm-settings-summoner {
            bottom: 110px !important;
        }
    }

    form {
        svg {
            color: var(--form-text);
        }
        html,
        button,
        input,
        select,
        textarea,
        .frc-text {
            color: var(--form-text);
        }
    }

    input,
    select,
    textarea {
        &::-webkit-input-placeholder {
            color: var(--form-text);
        }
        &::-moz-placeholder {
            color: var(--form-text);
        }
        color: var(--form-text);
    }
`;

export const GlobalStyle = createGlobalStyle`
  ${reset};
  ${base};
`;

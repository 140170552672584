import React, { FC } from "react";

import SCookieBanner from "./SCookieBanner";
import SCookieBannerInnerBottomBox from "./SCookieBannerInnerBottomBox";
import SCookieBannerInnerBox from "./SCookieBannerInnerBox";



const Banner: FC<{isActive: boolean; privacyLink: string; onAcceptCookie: () => boolean}> = ({ isActive, privacyLink, onAcceptCookie }) => 
  (
    <SCookieBanner active={isActive}>
      <SCookieBannerInnerBox>
      Wir sind datensparsam und achten auf Ihre Privatsphäre.
      Wir nutzen nur die technisch notwendigen Dienste, um Ihnen
      ein optimales Erlebnis bereitstellen zu können. Wir setzen weder
      eigene Cookies ein, noch verarbeiten wir persönliche Daten.
      Mit der Nutzung dieser Seite sind Sie damit einverstanden.
      Lesen Sie unter Datenschutz mehr dazu.
        <SCookieBannerInnerBottomBox>
          <a onClick={() => onAcceptCookie()}>{"Ich bin einverstanden "}</a>{" | "}
          <span></span><a href={`/${privacyLink}`}>{" Datenschutzerklärung"}</a>
        </SCookieBannerInnerBottomBox>
      </SCookieBannerInnerBox>
    </SCookieBanner>
  );

export default Banner;

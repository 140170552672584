import styled, { css } from "styled-components";
import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../constants/mediaquerys";

const Default = css`
    padding: 1rem;
    font-size: 1.0625em;
    line-height: 1.29412;
    display: flex;
    flex-flow: column;
    height: 100px;
    justify-content: center;
    color: #575756;
    display: inline;
`;

const Silver = css`
    height: auto;
`;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

const inViewState = css``;

const SCookieBannerInnerBox = styled.div`
    ${Default}
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
export default SCookieBannerInnerBox;

export default {
    blue: "#008bc5",
    brown: "#fcecc3",
    cloudBlue: "#E4EDF4",
    red: "#EE765E",
    lightBlue: "#83D0F5",
    mint: "#69c0ac",
    yellow: "#FED061",
    gray: "#A8A9AB",
    bgLight: "#FEE580", // "rgba(255,255,255, 0.5)"
    productPrime: "#d40046", // "rgba(255,255,255, 0.5)"
    productBg1: "#e6e7e9", // "rgba(255,255,255, 0.5)"
};

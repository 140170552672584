import { hexIsLight } from "src/common/utils/colorHelper";
import { css } from "styled-components";
import { DarkTheme, LightTheme } from "./globalStyle";
import { lightenDarkenColor } from "../../../common/utils/lightenDarkenColor";

const fontDeclaration = css`
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    a,
    li,
    ul,
    input,
    textarea,
    span,
    button,
    div {
        font-family: Arial, "sans-serif";
        hyphens: none;
    }
    body {
        overscroll-behavior: none;
    }
`;

type t27VariablesBaseStyleProps = {
    backgroundColor: string;
    theme?: any;
    primaryColor: string;
    /**
     * - enable to overwrite existing template inner scope style. default is false
     */
    shouldOverwriteStyle?: boolean;
};

const t27VariablesBaseStyle = css<t27VariablesBaseStyleProps>`
    ${({ shouldOverwriteStyle, backgroundColor = "#ffffff", primaryColor = "#FF0001" }) =>
        shouldOverwriteStyle
            ? css<t27VariablesBaseStyleProps>`
                  div {
                      --primary-100: ${({ primaryColor }) => primaryColor || "#FF0001"};

                      ${({ backgroundColor }) => (hexIsLight(backgroundColor || "#ffffff") ? LightTheme : DarkTheme)};
                      ${({ backgroundColor }) =>
                          backgroundColor &&
                          `${backgroundColor}`.length == 7 &&
                          hexIsLight(backgroundColor) &&
                          css`
                              --back-1: ${backgroundColor};
                              --back-2: ${lightenDarkenColor(backgroundColor, -6)};
                              --back-3: ${lightenDarkenColor(backgroundColor, -17)};
                          `};
                      ${({ backgroundColor }) =>
                          backgroundColor &&
                          `${backgroundColor}`.length == 7 &&
                          !hexIsLight(backgroundColor) &&
                          css`
                              --back-1: ${backgroundColor};
                              --back-2: ${lightenDarkenColor(backgroundColor, 6)};
                              --back-3: ${lightenDarkenColor(backgroundColor, 17)};
                          `};

                      ${fontDeclaration}
                  }
              `
            : css<t27VariablesBaseStyleProps>`
                  :root {
                      --primary-100: ${({ primaryColor }) => primaryColor || "#FF0001"};

                      ${({ backgroundColor }) => (hexIsLight(backgroundColor || "#ffffff") ? LightTheme : DarkTheme)};
                      ${({ backgroundColor }) =>
                          backgroundColor &&
                          `${backgroundColor}`.length == 7 &&
                          hexIsLight(backgroundColor) &&
                          css`
                              --back-1: ${backgroundColor};
                              --back-2: ${lightenDarkenColor(backgroundColor, -6)};
                              --back-3: ${lightenDarkenColor(backgroundColor, -17)};
                          `};
                      ${({ backgroundColor }) =>
                          backgroundColor &&
                          `${backgroundColor}`.length == 7 &&
                          !hexIsLight(backgroundColor) &&
                          css`
                              --back-1: ${backgroundColor};
                              --back-2: ${lightenDarkenColor(backgroundColor, 6)};
                              --back-3: ${lightenDarkenColor(backgroundColor, 17)};
                          `};
                  }

                  ${fontDeclaration}
              `}
`;

export default t27VariablesBaseStyle;

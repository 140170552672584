import { css } from "styled-components";
import { BREAKPOINTS_DM } from "./mediaquerys";

export default {
  h1Red: css`
      margin: 50px 0 21px 0;
      color: ${({ theme }) => theme.palette.red};
      font-weight: 700;
      font-size: 24px;
  `,
  h4Black: css`
    color: ${({ theme }) => theme.palette.black};
    font-family: Roboto,sans-serif;
    font-size: 16px;
    line-height: 1.4;
    margin-bottom: 5px;
    font-weight: 600;
    letter-spacing: -0.6px;
  `,

  pGray: css`
  font-family: Roboto,sans-serif;
  font-size: 13px;
  line-height: 1.4;
  padding-bottom: 3px;
  font-weight: 500;
  text-align: center;
  @media (${BREAKPOINTS_DM.silver_768}) {
    text-align: left;
  }
  `,
  SGray: css`
  font-family: Roboto,sans-serif;
  font-size: 11.5px;
  line-height: 1.4;
  padding-bottom: 12px;
  padding-right: 22px;
  `
  // p h2 h3 etc etc
};

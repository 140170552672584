import React, { FunctionComponent, useEffect, useState } from "react";
import Banner from "./Banner";
// import Siegel from "./Siegel";

const CookieBanner: FunctionComponent<{
  privacyLink: string;
  pageProps: Record<string, any>;
  theme: Record<string, any>;
}> = ({ privacyLink }) => {
  const [isActive, setActive] = useState(false);
  const [isAgreed, setAgreed] = useState(false);

  // const primaryColor = pageProps?.Settings?.themeColorPrimary || theme?.primary;
  // const secondaryColor =
  //   pageProps?.Settings?.themeColorSecondary || theme?.secondary;
  // const visualization = pageProps?.Settings?.cookieBannerVisualization;

  const acceptCookie: any = () => {
    try {
      window.localStorage.setItem("cookieAccepted", "true");
      setActive(false);
      setAgreed(true);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const cookieAccepted =
      typeof window !== "undefined" &&
      window.localStorage &&
      window.localStorage.getItem("cookieAccepted");
    if (cookieAccepted === null && isActive === false) {
      setActive(true);
      setAgreed(false);
    }
    if (cookieAccepted === "true" && isAgreed === false) {
      setActive(false);
      setAgreed(true);
    }
  }, [isActive, isAgreed, setActive, setAgreed]);

  return (
    <Banner
      isActive={isActive}
      privacyLink={privacyLink}
      onAcceptCookie={acceptCookie}
    />
  );
  // return (
  //   <>
  //     {visualization === "Banner" ? (
  //       <Banner
  //         isActive={isActive}
  //         privacyLink={privacyLink}
  //         onAcceptCookie={acceptCookie}
  //       />
  //     ) :
  //       (
  //         <Siegel
  //           isActive={isActive}
  //           primaryColor={primaryColor}
  //           secondaryColor={secondaryColor}
  //           privacyLink={privacyLink}
  //           onAcceptCookie={acceptCookie}
  //         />
  //       ) }
  //   </>
  // );
};

export { CookieBanner };

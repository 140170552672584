import styled, { css } from 'styled-components';
import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from '../../../constants/mediaquerys';

const Default = css`
position: fixed;
pointer-events: none;
opacity: 0;
transform: translate3d(-50%, 20px, 0);
transition: opacity ease 1s , transform ease 1s ;
bottom: 0;
left: 50%;
width: 100%;
background: #C2C2C3;
padding: 1rem;
text-align: center;
z-index: 99998;
color: #575756;
font-size: 17px;
p{
  display: inline;
}
a{
  display: inline;
  cursor: pointer;
  text-decoration: none;
}
`;

const Silver = css`

width: 65%;
`;

const Gold = css`
`;

const Platinum = css``;

const Diamond = css``;

const activeState = css`
pointer-events: visible;
opacity: 1;
transform: translate3d(-50%, 0, 0);
`;

const SCookieBanner = styled.div<any>`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`}; 
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
  ${({active}) => active ? activeState : ''};
`;
export default SCookieBanner;

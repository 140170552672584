export default {
    blue: "#008cd2",
    mint: "#04ab9f",
    lightGray: "#f1f1f1",
    backgroundMint: "#aad4cf",
    red: "#cd4464",
    bgLight: "#FEE580", // "rgba(255,255,255, 0.5)"
    productPrime: "#d40046", // "rgba(255,255,255, 0.5)"
    productBg1: "#e6e7e9", // "rgba(255,255,255, 0.5)"
    guestbookGreen: "#00929c",
}

export default {
    brown: "#816456",
    gray: "#666",
    bgLight: "#FEE580", // "rgba(255,255,255, 0.5)"
    productPrime: "#d40046", // "rgba(255,255,255, 0.5)"
    productBg1: "#e6e7e9", // "rgba(255,255,255, 0.5)"
    guestbookPrimary: "#d4c1d4",
    actionBanner: '#d4c1d4',
    lightGray: "#f1f1f1",
    backgroundMint: "#aad4cf"
};

import React, { FunctionComponent } from "react";
import { IContext, getPage, IMetaInformation } from "../../index";
import Head from "next/head";

export const SEO: FunctionComponent<IContext> = (props: IContext) => {
    const page = getPage(props);
    const template = props?.Settings?.template || "t99";
    const templateNumber = parseInt(template.replace("t", ""));
    const pub = props?.CompanyInformationPublic;
    const line = props?.CompanyInformationPrivate?.line;
    const favicon = props?.CompanyInformationPublic?.favicon?.src;
    //@ts-ignore
    const seoData = page?.extras?.find((e) => e?.__typename === "MetaInformation") as IMetaInformation;

    let companyName = null;
    if (pub) {
        companyName = pub?.companyNameOverwriteContact || pub?.companyName;
    }

    const seo_pageTitle = (getPage(props) as any)?.seo_pageTitle;
    const seo_metaDescription = (getPage(props) as any)?.seo_metaDescription;
    const title = seo_pageTitle ? seo_pageTitle : seoData?.pageTitle ? seoData?.pageTitle : companyName || "";
    const description = seo_metaDescription
        ? seo_metaDescription
        : seoData?.description ||
          (pub?.place
              ? `${companyName || ""} in ${pub?.street ?? ""}${
                    pub?.place ? `, ${pub?.place} ${pub?.postcode ?? ""}` : ""
                }`
              : undefined);

    const metaTags = seoData?.metaTags || pub?.place;
    const author = seoData?.author;

    let isRootContent = false;

    if (
        (props?.PageNews?.slug && props?.PageNews?.slug == page?.slug) ||
        (props?.PageBrand?.slug && props?.PageBrand?.slug == page?.slug)
    ) {
        isRootContent = true;
    }

    return (
        <>
            <Head>
                <title>{title}</title>
                {description && (
                    <>
                        <meta name="description" content={description} />
                        <meta property="og:description" content={description} />
                        <meta name="twitter:description" content={description} />
                    </>
                )}
                {metaTags && <meta name="keywords" content={metaTags} />}
                {author && <meta name="author" content={author} />}
                <meta name="language" content="de" />
                {!(page as any)?.seo_noIndex && !isRootContent ? (
                    <meta name="robots" content="INDEX,FOLLOW" />
                ) : (
                    <meta name="robots" content="noindex" />
                )}
                <meta name="geo.region" content="DE" />
                {pub?.place && pub?.place != "" ? (
                    <>
                        <meta name="geo.placename" content={`${pub.place}`} />
                        <meta name="city" content={`${pub.place}`} />
                    </>
                ) : null}
                {pub?.postcode && pub?.postcode != "" ? (
                    <>
                        <meta name="zipcode" content={`${pub.postcode}`} />
                    </>
                ) : null}
                <meta property="og:title" content={title} />
                {props.host ? <meta property="og:url" content={`https://${props.host}`} /> : null}

                <meta property="og:type" content="website" />
                <meta name="twitter:title" content={title} />

                {favicon ? (
                    <>
                        <link rel="icon" type="image/png" sizes="16x16" href={`${favicon}?width=16&height=16&f=png`} />
                        <link rel="icon" type="image/png" sizes="32x32" href={`${favicon}?width=32&height=32&f=png`} />
                        <link rel="icon" type="image/png" sizes="48x48" href={`${favicon}?width=48&height=48&f=png`} />
                        <link rel="apple-touch-icon" sizes="180x180" href={`${favicon}?width=180&height=180&f=png`} />
                    </>
                ) : line == "kw" || line == "gk" || line == "kg" ? (
                    <>
                        <link rel="icon" type="image/ico" sizes="16x16" href={`/images/${line}_favicon_16.ico`} />
                        <link rel="icon" type="image/ico" sizes="32x32" href={`/images/${line}_favicon_32.ico`} />
                    </>
                ) : line == "dk" || line == "pk" ? (
                    <>
                        {/* https://favicon.io/favicon-converter/ */}
                        <link
                            rel="apple-touch-icon"
                            sizes="180x180"
                            href={`/images/favs/${line}/apple-touch-icon.png`}
                        />
                        <link
                            rel="icon"
                            type="image/png"
                            sizes="32x32"
                            href={`/images/favs/${line}/favicon-32x32.png`}
                        />
                        <link
                            rel="icon"
                            type="image/png"
                            sizes="16x16"
                            href={`/images/favs/${line}/favicon-16x16.png`}
                        />
                        <link rel="manifest" href="/site.webmanifest"></link>
                    </>
                ) : null}
                {/* do not know if we still need this fontawesome and if for all templates  */}
                {templateNumber > 7 && templateNumber < 27 && (
                    <>
                        <script defer src="/fonts/fontawesome/js/all.js" />
                        <link href="/fonts/fontawesome/css/all.css" rel="stylesheet" />
                    </>
                )}
            </Head>
        </>
    );
};

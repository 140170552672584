import { css } from "styled-components";

export default {
    h1Red: css`
      margin: 50px 0 21px 0;
      color: ${({ theme }) => theme.palette.red};
      font-weight: 700;
      font-size: 24px;
  `,
    // p h2 h3 etc etc
};

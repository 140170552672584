import styled, { css } from "styled-components";
import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../constants/mediaquerys";

const Default = css`
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 1.1rem;
    margin-bottom: 0;
    margin-top: 1rem;
    span{
      display: block;
      width: 4px;
    }
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

const SCookieBannerInnerBottomBox = styled.div`
    ${Default}
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
export default SCookieBannerInnerBottomBox;

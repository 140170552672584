const colors = {
    blue: "#009FE3",
    green: "#33665e",
    lightMint: "#DAF2F1",
    bgLight: "#FEE580", // "rgba(255,255,255, 0.5)"
    productPrime: "#d40046", // "rgba(255,255,255, 0.5)"
    productBg1: "#e6e7e9", // "rgba(255,255,255, 0.5)"
};

export default colors;

export default {
  black: "#000000",
  white: "#ffffff",
  textColor: "#7d7261;",
  textColor1: "#ffffff",
  black50: "#505050",
  highlight: "#ffffff",
  white80: "rgba(255,255,255, 0.8)",
  primary: "#cbbb89",
  secondary: "#f9b000",
  trans50: "rgba(255,255,255, 0.5)",
  bg: "#f9b000",
  bgInverted: "#7d7261",
  bgLight: "#e6e7e9",
};

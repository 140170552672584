export function convertHex(hexCode, opacity = 1){
  var hex = hexCode.replace('#', '');

  if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }

  var r = parseInt(hex.substring(0,2), 16),
      g = parseInt(hex.substring(2,4), 16),
      b = parseInt(hex.substring(4,6), 16);

  /* Backward compatibility for whole number based opacity values. */
  if (opacity > 1 && opacity <= 100) {
      opacity = opacity / 100;   
  }
  
  return 'rgba('+r+','+g+','+b+','+opacity+')';
}


export function rgba2hex(orig) {
  var a, isPercent,
  rgb = orig.replace(/\s/g, '').match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i),
  alpha = (rgb && rgb[4] || "").trim(),
  hex = rgb ?
  (rgb[1] | 1 << 8).toString(16).slice(1) +
  (rgb[2] | 1 << 8).toString(16).slice(1) +
  (rgb[3] | 1 << 8).toString(16).slice(1) : orig;

  if (alpha !== "") { a = alpha; }
  else { a = 0o01; }
  hex = hex + a;

  return hex;
}

export function hexIsLight(color) {
  const hex = color.replace('#', '');
  const c_r = parseInt(hex.substr(0, 2), 16);
  const c_g = parseInt(hex.substr(2, 2), 16);
  const c_b = parseInt(hex.substr(4, 2), 16);
  const brightness = ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000;
  return brightness > 155;
}